<template>
  <div  class="px-15px px-lg-25px">
    <div class="aiz-titlebar mt-2 mb-4">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="h3">{{ $t('tian-jia-nin-de-you-hui-quan') }}</h1>
        </div>
      </div>
    </div>

    <div class="row gutters-5">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0 h6">{{ $t('you-hui-quan-xin-xi-tian-jia') }}</h5>
          </div>
            
          <div class="card-body">
            <form class="form-horizontal" >
              <div class="form-group row">
                <label class="col-lg-3 col-from-label" for="name">{{ $t('you-hui-quan-lei-xing') }}</label>
                <div class="col-lg-9">
                  <a-select v-model="form.type" class="form-control clear" required size="large" :placeholder="$t('qing-xuan-ze')">
                    <a-select-option value="">{{ $t('qing-xuan-ze-0') }}</a-select-option>
                    <a-select-option :value="item.value" v-for="(item, i) in typeList" :key="i">{{item.label}}</a-select-option>
                  </a-select>
                </div>
              </div>

              <div id="coupon_form" v-show="form.type">
                <div class="card-header mb-2">
                  <h3 class="h6">{{form.type == 1 ? $t('Add your product base coupon') : $t('Add basic coupons to your shopping cart')}}</h3>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-from-label" for="code">{{ $t('you-hui-quan-dai-ma') }}</label>
                  <div class="col-lg-9">
                    <input type="text" :placeholder="$t('you-hui-quan-dai-ma-0')" id="code" name="code" class="form-control" required="">
                  </div>
                </div>
                <div class="form-group row" v-if="form.type == 1">
                  <label class="col-lg-3 col-from-label" for="code">{{ $t('chan-pin') }}</label>
                  <div class="col-lg-9">
                    <a-select mode="multiple" v-model="form.product" class="form-control clear" size="large" :placeholder="$t('qing-xuan-ze-1')">
                      <a-select-option value="">{{ $t('qing-xuan-ze-2') }}</a-select-option>
                      <a-select-option :value="item.value" v-for="(item, i) in productList" :key="i">{{item.label}}</a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="form-group row" v-if="form.type == 2">
                  <label class="col-lg-3 col-from-label" for="code">{{ $t('zui-di-gou-wu') }}</label>
                  <div class="col-lg-9">
                    <input type="number" v-model="form.min" min="0" step="0.01" :placeholder="$t('zui-di-gou-wu-0')" name="discount" class="form-control" required="">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-lg-3 col-from-label" for="code">{{ $t('ri-qi') }}</label>
                  <div class="col-lg-9">
                    <div class="form-control clear" >
                      <a-range-picker v-model="form.date" size="large" />
                    </div>
                  </div>
                </div>
                <div class="form-group row" v-if="form.type == 2">
                  <label class="col-lg-3 col-from-label" for="code">{{ $t('zui-da-zhe-kou-jin-e') }}</label>
                  <div class="col-lg-9">
                    <input type="number" v-model="form.max" min="0" step="0.01" :placeholder="$t('zui-da-zhe-kou-jin-e-0')" name="discount" class="form-control" required="">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-lg-3 col-from-label">{{ $t('zhe-kou') }}</label>
                  <div class="col-lg-7">
                    <input type="number" lang="en" min="0" step="0.01" :placeholder="$t('zhe-kou-0')" name="discount" class="form-control" required="">
                  </div>
                  <div class="col-lg-2">
                    <a-select  v-model="form.percentType" class="form-control clear" size="large" :placeholder="$t('qing-xuan-ze-3')">
                      <a-select-option :value="item.value" v-for="(item, i) in percentTypeList" :key="i">{{item.label}}</a-select-option>
                    </a-select>
                  </div>
                </div>

              </div>
              <div class="form-group mb-0 text-right">
                  <button type="submit" class="btn btn-primary">{{ $t('bao-cun') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

   
  </div>
</template>
<script>
import { Popup } from 'vant'
import pageBar from '@/components/page'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      showPopup: false,
      form: {
        type: '',
        product: [],
        date: [],
        percentType: 1,
        min: ''
      },
      typeList: [
        {
          value: 1,
          label: this.$t('dui-yu-chan-pin')
        },
        {
          value: 2,
          label: this.$t('dui-yu-zong-ding-dan')
        },
      ],
      percentTypeList: [
        {
          value: 1,
          label: this.$t('zong-e')
        },
        {
          value: 2,
          label: this.$t('bai-fen')
        },
      ],
      productList: []
    }
  },
  methods: {
    openModal() {
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>